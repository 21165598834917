$input-background-color: #fafafa;
$max-form-width: 840px;

.button.wide {
  padding-left: 4rem;
  padding-right: 4rem;
}

.button.widest {
  padding-left: 8rem;
  padding-right: 8rem;
}


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cs-primary: mat.define-palette(mat.$indigo-palette);
$cs-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cs-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cs-theme: mat.define-light-theme((
  color: (
    primary: $cs-primary,
    accent: $cs-accent,
    warn: $cs-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cs-theme);

@import "./app/styles/variables/variables";

@import "~bulma-responsive-tables/bulma-responsive-tables";
@import "~bulma/bulma";
@import "~bulma-switch";

$fa-font-path: "~font-awesome/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Covered+By+Your+Grace&family=Itim&family=Pacifico&family=Parisienne&family=Rock+Salt&display=swap');
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~quill/dist/quill.snow.css';
@import '~@angular/cdk/overlay-prebuilt.css';

html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  //overflow: hidden;
  font-size: 14px;
  @media screen and (max-width: 1280px) and (max-height: 720px) {
    font-size: 12px;
  }
}

* {
  box-sizing: border-box;
}
.menu-dropdown-items-list{
  .mat-menu-content{
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    .mat-menu-item{
      font-style: normal;
      font-weight: 600;
      font-size: 1.28rem !important;
      line-height: 25px;
      text-align: right;
      color: #222222;
      height: fit-content;
      margin-top: 7.5px;
      margin-bottom: 7.5px;
      &.active{
        color: #22B07D;
      }
      &.text-danger{
        color: #FD3E30;
      }
      &.left-aligned-text {
        text-align: left;
      }
    }
    p {
      text-align: right;
      font-style: normal;
      font-weight: 600;
      font-size: 1.28rem !important;
      line-height: 25px;
      color: #222222;
      height: fit-content;
      margin-top: 7.5px;
      margin-bottom: 7.5px;
      &.active{
        color: #22B07D;
        font-size: 0.75rem !important;
      }
    }
  }
}
.mat-menu-panel{
  min-width: 199px !important;
  &.medium-width{
    min-width: 130px !important;
  }
}

lib-ngx-image-zoom {
  padding-top: 6px;
  margin-right: 1px;
}
.gallery-wrapper {
  overflow: hidden;
  gallery {
    height: 240px;
    background: none;
  }
}

gallery-thumbs {
  margin-right: 1rem;
  border: 1px solid #ababab;
  border-radius: 10px;
  gallery-thumb {
    gallery-image {
      padding: 10px;

      .g-image-item {
        border-radius: 10px;
      }
    }
  }
}

gallery-slider {
  gallery-item {
    margin: 0 5px;
    padding: 0 1px 0 0;
  }
}

.container {
  lib-ngx-image-zoom {
    opacity: 0%;
    &:hover {
      opacity: 100%;
    }
    div.ngxImageZoomContainer {
      //can't deal with selectors level
      height: unset !important;
      width: unset !important;
      .ngxImageZoomFullContainer {
        background-color: white;
      }
    }
  }
}

lightbox {
  padding: 1rem;
  background: white;
  gallery {
    background: white;
    gallery-thumbs {
      border: none;
    }
  }
}


#top-bar {
  display: flex;
  justify-content: space-between;
  align-content: baseline;
  min-height: 4rem;
  padding: 0.75rem 0;
  margin-bottom: 3rem;
  border-bottom: 1px solid #ddd;
  top: 68px;
  background-color: #fff;
  z-index: 2;

  #bread-crumbs {
    align-self: center;

    nav.xng-breadcrumb-root  {
      ol.xng-breadcrumb-list {
        font-size: 0.9rem;
        font-weight: 400;
        color: #7a7a7a;
        margin: 0 auto;

        label.xng-breadcrumb-trail {
          color: $cs-green;
        }
      }
    }
  }

  #actions-line {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 40px;

    a:not(.button) {
      color: $body-color;
    }

    a:not(.button):hover {
      color: $cs-green;
    }
  }
}

.cs-icon-button {
  cursor: pointer;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
}

.hero {
  margin-bottom: 1rem;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

.cs-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.markdown {
  ul {
    list-style: disc;
  }

  ul,ol {
    padding-left: 1.2em;
  }
}
.navbar {
  align-items: center;
  .navbar-menu {
    position: absolute;
    background-color: #fff;
    right: 0;
    margin-right: 0 !important;
  }
}

.green-snackbar {
  background: $cs-green;
  color: white;
}

.red-snackbar {
  background: $red;
  color: white;
}

.info-green-tag {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 4px 15px;
  background-color: $light-bg;
  width: fit-content;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
}

quill-editor {

  .ql-toolbar {
    background-color: #fafafa;
    border-color: #dbdbdb;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .ql-container {
    font-size: 1.25rem;
    background-color: #fafafa;
    border-color: #dbdbdb;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    min-height: 20vh;

    font-family: "Nunito Sans", sans-serif;
    color: #222222;

    .ql-tooltip {
      left: 0;
    }
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  padding: 15px 0 0 15px;
}

p, div, span, button, a, label {
  font-family: "Nunito Sans", sans-serif !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $cs-green;
}
.ql-snow {
  .ql-editor {
    padding: 0;
    font-size: 1rem;
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: none;
    }
    a {
      color: $cs-green;
      text-decoration: none;
      :hover {
        color: $cs-green;
        text-decoration: underline;
        text-decoration-color: $cs-green;
      }
    }
  }
}

.banner {
  background: $black;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 600;
  color: $white;
  .banner-content {
    @media screen and (min-width: 768px){
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 767px){
      display: block;
    }
    @media screen and (max-width: 1023px) {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 0;
    }
    @media screen and (min-width: 1200px) and (max-width: 1216px){
      width: 1100px !important;
    }
    @media screen and (min-width: 1408px) {
      width: 1216px !important;
    }
    .banner-button {
      @media screen and (min-width: 768px) {
        margin-left: 3rem;
        font-size: 1.2rem;
      }
      @media screen and (max-width: 767px) {
        margin-top: -2.5rem;
        font-size: 1rem;
      }

      padding: 0 1rem;
      margin-bottom: 0.25rem;
    }
    .banner-text {
      margin: 0 auto;
      .ql-snow {
        .ql-editor {
          padding: 0;
          line-height: 1rem;
          text-align: center;
          min-height: 1rem;
          strong {
            color: $white;
          }
          p {
            @media screen and (min-width: 768px) {
              font-size: 1rem !important;
            }
            @media screen and (max-width: 767px) {
              font-size: 0.8rem !important;
            }
          }
          a {
            //border: 1px solid white !important;
            //padding: 0.2rem 0.5rem;
            border-radius: 5px;
            color: $white;
            text-decoration: underline;

            :hover {
              text-decoration: none;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.green-text {
  color: $cs-green;
}

.justify-content-between{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hero {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1408px){
  .container {
    max-width: 1100px !important;
  }
}

@media screen and (min-width: 1408px) {
  .container {
    max-width: 1216px !important;
  }
}

.under-top-toolbar {
  padding-top: 1rem;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
}

html, body { height: 100%; }
body { margin: 0; }

::ng-deep #hubspot-messages-iframe-container {
  z-index: 1000 !important;
}

.collapsed {
  display: none !important;
  visibility: hidden !important;
}

@import "./colors";

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-Regular.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-SemiBold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-Bold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-Italic.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-SemiBold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../../assets/fonts/nunito-sans/NunitoSans-BoldItalic.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$family-primary: 'Nunito Sans';
$size-6: 0.9rem;
//$size-4: 1.3rem;

$grey-darker: $body-color;

h1 {
  font-size: 2rem !important;
  font-weight: normal;
  line-height: 123%;
  letter-spacing: 0;
}

h2 {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 138%;
  letter-spacing: 0;
}

h3 {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  line-height: 130%;
  letter-spacing: 0;
}

h4 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 130%;
  letter-spacing: 0;
}

p, .regular {
  font-size: 1rem !important;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0;
}
